



















































































import { Observer } from 'mobx-vue'
import { Component, Vue } from 'vue-property-decorator'
import { invitationController } from './invitation-controller'

@Observer
@Component({
  components: {
    observer: () => import('vue-intersection-observer'),
  },
})
export default class extends Vue {
  controller = invitationController

  loadMore() {
    if (this.controller.canLoadMoreUserInvite) {
      this.controller.loadMoreUserInvite()
    }
  }
}
